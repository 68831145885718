.status-pill {
  border-radius: 30px;
  padding: 12px 18px;
  background: $pale_yellow;
  color: $dark_blue;
  text-align: center;
  display: inline-block;
  margin-bottom: 20px
}

.pickup-times {
  background: $dark_blue_10;
  padding: 15px;
  border-radius: 12px;
  margin-bottom: 30px;

  h3 {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .icon-pickup-times {
      margin-right: 10px;
    }
  }

  .pickup-times-content {
    display: flex;
    flex-direction: row;
    align-items: center;

    .btn {
      margin-left: 10px;
      margin-bottom: 0;
    }
  }
}

.request-section {

  .container-fluid {
    margin-bottom: 50px;
    padding-bottom: 50px;
    border-bottom: 1px solid $gray_light;
  }

  &:last-child {

    .container-fluid {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: 0;
    }
  }
}

.quantity-received {
  margin-bottom: 0;
  max-width: 70px;
}

.fulfilled {
  color: $primary;
  font-weight: 700
}