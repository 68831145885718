.simple-container {
  padding: 15px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  &.subtle {
    opacity: .6;
  }
}

.version-footer {
  padding: 15px;
  width: 100%;
  max-width: 1200px;
  color: #ccc;
  margin: 0 auto;
}

.error-boundary {
  width: 100%;
  max-width: 400px;
  padding: 15px;
  margin: 0 auto;

  .error-boundary-inner {
    border-radius: 12px;
    border: 1px solid rgba(0,0,0,.3);
    padding: 20px;

    .btn {
      margin-bottom: 0;
    }
  }
}

.heading-with-actions {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
    align-items: center;
  }

  .actions {
    .btn {
      margin-left: 10px;

      &:first-child {
        margin-left: 0;
      }
    }

  }
}