.cart {
  position: fixed;
  bottom: 40px;
  right: 40px;
  width: 100%;
  max-width: 350px;
  max-height: 600px;
  border-radius: 12px;
  box-shadow: 2px 4px 25px 0 rgba(0,0,0,.2);
  z-index: 1000;
  background: $white;
  transition: .25s linear;
  overflow: hidden;

  &.is-inactive {
    height: 70px;
    width: 150px;
    border-radius: 35px;
    overflow: hidden;
  }

  &.is-active {

    .cart-heading {

      &:after {
        content: '×';
        font-weight: 700;
        font-size: 24px;
        line-height: 23px;
        text-align: center;
        position: absolute;
        top: 50%;
        right: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        width: 30px;
        background: $white;
        border-radius: 30px;
        transform: translateY(-50%);
      }

      &:hover {
        color: $primary;
      }
    }
  }

  .cart-heading {
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: .25s linear;
    padding: 15px;
    cursor: pointer;
    position: relative;

    &:hover {
      background: $primary_20o;
    }

    .cart-icon {
      height: 40px;
      width: 40px;
      min-width: 40px;
      background: $primary;
      border-radius: 50%;
      margin-right: 15px;
      font-size: 20px;
      line-height: 40px;
      text-align: center;
      font-weight: 700;
      color: $white;
      display: flex;
      align-items: center;

      .item-count {
        width: 100%;
      }
    }

    h4 {
      margin-bottom: 0;
      color: $gray_dark;
      font-weight: 700;
    }
  }

  .cart-body {
    padding: 15px;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        padding-bottom: 10px;
        margin-bottom: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom: 1px solid rgba(0,0,0,.1);

        &:last-child {
          border-bottom: 0px;
        }

        .item-name {
          flex: 1;
        }

        .item-quantity {
          width: 60px;
          margin-bottom: 0;

          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
          }

          &[type=number] {
            -moz-appearance: textfield;
          }
        }

        .item-remove {
          width: 20px;
          text-align: center;
          cursor: pointer;
          height: 40px;
          width: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 5px;
          font-size: 20px;
          font-weight: 700;
          position: relative;

          &:hover {
            color: $red;
          }
        }
      }
    }
  }
}