// Brand Colors
$white: #FFFFFF;
$gray_light: #e0e0e0;
$gray_medium: #888888;
$gray_mediumdark: #444444;
$gray_dark: #212121;

$primary: #0DBC57;
$primary_10: rgba(13,188,87,.1);
$primary_10o: #E9F4E9;
$primary_20o: #D7ECD7;
$primary_40o: #B3DBB4;
$primary_hov: #00A345;
$red: red;
$pale_yellow: #F8F0BF;
$dark_blue: #23314b;
$dark_blue_10: rgba(35,49,75,.1);