/*
==============================================================
Typography
============================================================== */

// @font-face {
//   font-family: 'Moderat';
//   src: url('Moderat-Black.eot');
//   src: url('Moderat-Black.eot?#iefix') format('embedded-opentype'),
//       url('Moderat-Black.woff2') format('woff2'),
//       url('Moderat-Black.woff') format('woff'),
//       url('Moderat-Black.svg#Moderat-Black') format('svg');
//   font-weight: 900;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Moderat';
//   src: url('Moderat-Bold.eot');
//   src: url('Moderat-Bold.eot?#iefix') format('embedded-opentype'),
//       url('Moderat-Bold.woff2') format('woff2'),
//       url('Moderat-Bold.woff') format('woff'),
//       url('Moderat-Bold.svg#Moderat-Bold') format('svg');
//   font-weight: bold;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Moderat';
//   src: url('Moderat-Light.eot');
//   src: url('Moderat-Light.eot?#iefix') format('embedded-opentype'),
//       url('Moderat-Light.woff2') format('woff2'),
//       url('Moderat-Light.woff') format('woff'),
//       url('Moderat-Light.svg#Moderat-Light') format('svg');
//   font-weight: 300;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Moderat';
//   src: url('Moderat-Regular.eot');
//   src: url('Moderat-Regular.eot?#iefix') format('embedded-opentype'),
//       url('Moderat-Regular.woff2') format('woff2'),
//       url('Moderat-Regular.woff') format('woff'),
//       url('Moderat-Regular.svg#Moderat-Regular') format('svg');
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Moderat';
//   src: url('Moderat-Thin.eot');
//   src: url('Moderat-Thin.eot?#iefix') format('embedded-opentype'),
//       url('Moderat-Thin.woff2') format('woff2'),
//       url('Moderat-Thin.woff') format('woff'),
//       url('Moderat-Thin.svg#Moderat-Thin') format('svg');
//   font-weight: 100;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Moderat';
//   src: url('Moderat-Medium.eot');
//   src: url('Moderat-Medium.eot?#iefix') format('embedded-opentype'),
//       url('Moderat-Medium.woff2') format('woff2'),
//       url('Moderat-Medium.woff') format('woff'),
//       url('Moderat-Medium.svg#Moderat-Medium') format('svg');
//   font-weight: 500;
//   font-style: normal;
//   font-display: swap;
// }

body {
  font-family: Moderat, sans-serif;
  font-weight: 400;
  font-style: normal;
}

h1, h2, h3, h4, h5, h6 {
  color: $gray_dark; }

h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
  font-weight: inherit; }

h1,
.h1 {
  font-size: 40px;
  line-height: 50px;
  margin-bottom: 15px;
  font-weight: 700;
  color: $dark_blue;
  font-family: Moderat, sans-serif;
  font-weight: 400;
  font-style: normal;
}

h2 {
  font-size: 28px;
  line-height: 32px;
  margin-bottom: 15px;
  font-weight: 700;
  color: $dark_blue;
  font-family: Moderat, sans-serif;
  font-weight: 400;
  font-style: normal;
}

h3,
.h3 {
  font-size: 26px;
  line-height: 32px;
  margin-bottom: 15px;
  font-weight: 600;
  color: $dark_blue;
}

h4 {
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 15px;
  font-weight: 400;
  color: #585858;
}

h5 {
  font-size: 16px;
  line-height: 22px;
  color: $gray_dark;
  text-transform: uppercase;
}

h6 {
  font-size: 14px;
  line-height: 20px;
  color: $gray_dark;
}

p {
  font-size: 16px;
  line-height: 1.4;
  color: #585858;
  margin: 0 0 20px 0;
}