.no-users {
	padding: 15px 23px;
}

.organization-item {
  margin-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
  flex: 0 0 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;


  @media only screen and (min-width: 992px) {
    border-bottom: 1px solid rgba(0,0,0,.1);
  }

  &:last-child {
    border-bottom: none;
  }

  &.column-headings {
    font-weight: 700;
    border-bottom: 1px solid rgba(0,0,0,.2);
    font-size: 14px;
    display: none;
    @media only screen and (min-width: 992px) {
      display: block;
    }

    .organization-item-content {
      padding: 15px 8px;
      height: 100%;
    }
  }

  @media only screen and (min-width: 480px) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  @media only screen and (min-width: 768px) {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  @media only screen and (min-width: 992px) {
    margin-bottom: 0;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .organization-item-content {
    border-radius: 12px;
    border: 1px solid rgba(0,0,0,.2);
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    @media only screen and (min-width: 992px) {
      padding: 8px;
      border-radius: 0;
      border: none;
      flex-direction: row;
      align-items: center;
      flex-shrink: 0;
    }
  }

  .item-name-description {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    @media only screen and (min-width: 992px) {
      width: 25%;
      flex-shrink: 0;
    }

    .item-name {
      font-weight: 700;
      font-size: 24px;
      line-height: 1.1em;
      margin-top: 5px;
      margin-bottom: 5px;

      @media only screen and (min-width: 992px) {
        font-size: 18px;
        flex-shrink: 0;
      }
    }

    .item-description {
      font-size: 12px;
      line-height: 1.2em;
      flex-shrink: 0;
    }

  }

  .item-email {
    margin-bottom: 10px;
    @media only screen and (min-width: 992px) {
      width: 20%;
      flex-shrink: 0;
    }
  }

  .item-phone {
    margin-bottom: 10px;
    @media only screen and (min-width: 992px) {
      width: 15%;
      flex-shrink: 0;
    }
  }

  .item-is-subbed {
    margin-bottom: 10px;
    @media only screen and (min-width: 992px) {
      width: 20%;
      flex-shrink: 0;
    }
  }

  .item-actions {
    display: flex;
    flex-direction: row;
    position: relative;

    .btn {
      margin-right: 10px;
      margin-bottom: 0;

      &:last-child {
        margin-right: 0;
      }
    }
    @media only screen and (min-width: 992px) {
      width: 20%;
      margin-bottom: 10px;
      justify-content: flex-end;
      flex-shrink: 0;
    }
  }

  @media only screen and (min-width: 992px) {
    .item-name-description,
    .item-email,
    .item-phone,
    .item-is-subbed,
    .item-actions {
      margin-bottom: 0;
    }
  }
}