.navbar {
  padding: 15px 0;
  background: $dark_blue !important;
}

.navbar-toggler {
  margin-bottom: 0;
  transition: background .15s linear;

  &:focus {
    box-shadow: none;
  }
}

.navbar-brand {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none !important;

  .logo-mark {
    margin-right: 10px;
    width: 100%;
    max-width: 50px;
    transition: .15s linear;
  }

  .logo-text{
    text-decoration: none;
    transition: .15s linear;
  }

  &:hover {
    .logo-mark {
      transform: scale(1.05);
    }

    .logo-text{
      text-decoration: none !important;
      color: $primary;
    }
  }
}
