.category-section {
  margin-bottom: 50px;
}

.category-heading {
  text-transform: capitalize;
}

.category-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  margin-left: -15px;

  @media only screen and (min-width: 992px) {
    border-radius: 12px;
    border: 1px solid rgba(0,0,0,.2);
    margin-left: 0px;
  }
}

.food-item {
  margin-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
  flex: 0 0 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;

  .pickup-quantity-reason {
    padding-right: 8px;

    input {
      max-width: 100%;
    }

    .input-why-less {

    }

    .value-why-less {
      padding: 8px 8px 8px 8px;
      font-size: 12px;
    }
  }

  @media only screen and (min-width: 992px) {
    border-bottom: 1px solid rgba(0,0,0,.1);
  }

  &:last-child {
    border-bottom: none;
  }

  &.column-headings {
    font-weight: 700;
    border-bottom: 1px solid rgba(0,0,0,.2);
    font-size: 14px;
    display: none;
    @media only screen and (min-width: 992px) {
      display: block;
    }

    .food-item-content {
      padding: 15px 8px;
      height: 100%;
    }
  }

  @media only screen and (min-width: 480px) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  @media only screen and (min-width: 768px) {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  @media only screen and (min-width: 992px) {
    margin-bottom: 0;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .food-item-content {
    border-radius: 12px;
    border: 1px solid rgba(0,0,0,.2);
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    @media only screen and (min-width: 992px) {
      padding: 8px;
      border-radius: 0;
      border: none;
      flex-direction: row;
      align-items: center;
      flex-shrink: 0;
    }
  }

  .item-name-description {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    @media only screen and (min-width: 992px) {
      width: 25%;
      flex-shrink: 0;
    }

    .item-name {
      font-weight: 700;
      font-size: 24px;
      line-height: 1.1em;
      margin-top: 5px;
      margin-bottom: 5px;

      @media only screen and (min-width: 992px) {
        font-size: 18px;
        flex-shrink: 0;
      }
    }

    .item-description {
      font-size: 12px;
      line-height: 1.2em;
      flex-shrink: 0;
    }

  }

  .item-location {
    margin-bottom: 10px;
    @media only screen and (min-width: 992px) {
      width: 10%;
      flex-shrink: 0;
    }
  }

  .item-condition {
    margin-bottom: 10px;
    @media only screen and (min-width: 992px) {
      width: 10%;
      flex-shrink: 0;
    }
  }

  .item-use-by {
    margin-bottom: 10px;
    @media only screen and (min-width: 992px) {
      width: 10%;
      flex-shrink: 0;
    }
  }

  .item-unit-weight {
    margin-bottom: 10px;
    @media only screen and (min-width: 992px) {
      width: 15%;
      flex-shrink: 0;
    }
  }

  .item-units-available {
    margin-bottom: 10px;
    @media only screen and (min-width: 992px) {
      width: 10%;
      flex-shrink: 0;
    }
  }

  .meal-item-list {
    margin-bottom: 5px;
    margin-bottom: 10px;
    @media only screen and (min-width: 992px) {
      width: 25%;
      flex-shrink: 0;
    }

    &.non-admin {
      @media only screen and (min-width: 992px) {
        width: 45%;
      }
    }

    .meal-item {
      margin-bottom: 5px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .btn {
        margin-bottom: 0;
      }

      .meal-item-name {
        font-weight: 700;
      }
    }
  }

  .item-actions {
    display: flex;
    flex-direction: row;
    position: relative;

    .btn {
      margin-right: 10px;
      margin-bottom: 0;

      &:last-child {
        margin-right: 0;
      }
    }
    @media only screen and (min-width: 992px) {
      width: 20%;
      margin-bottom: 10px;
      justify-content: flex-end;
      flex-shrink: 0;
    }
  }

  @media only screen and (min-width: 992px) {
    .item-name-description,
    .item-location,
    .item-condition,
    .item-use-by,
    .item-unit-weight,
    .item-units-available,
    .meal-item-list,
    .item-actions {
      margin-bottom: 0;
    }
  }
}

.popover-heading {
  padding-right: 40px;
}

.popover-arrow {
  display: none !important; // Temporarily hiding the arrow until we fix the position of it
}

.popover-body {
  box-shadow: 0 6px 16px 0 rgb(0,0,0,.2);
}

.popover-close {
  position: absolute;
  top: 10px;
  right: 10px;
  height: 40px;
  width: 40px;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  opacity: .5;
  transition: .15s linear;
  cursor: pointer;
  overflow: hidden;
  text-indent: -9999px;

  &:hover {
    opacity: 1;
  }
}

.required {
  font-weight: 700;
  color: $red;
}

.conversion-table-trigger {
  position: relative;
  display: inline-block;
  margin-bottom: 20px;
}

.conversion-table {
  border-width: 1px;
  border-color: $gray_light;
  margin-bottom: 0;

  thead td {
    font-weight: 700;
    border-width: 1px;
    border-color: $gray_light !important;
  }

  td {
    padding: 5px;
    border-width: 1px;
    border-color: $gray_light
  }
}

.danger-zone {
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 12px;
  border: 1px solid $red;
  background: rgba(255,0,0,.05);

  .danger-message {
    color: $red;
    text-align: center;
    font-weight: 700;
  }
}

.item-meal-size {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.item-meal-size-quantity {
  padding: 0 5px 0 10px;
}

.item-quantity {
  text-align: right;
  padding: 0 5px 0 10px;
}
