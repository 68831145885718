.authentication-form {
  border-radius: 12px;
  border: 1px solid rgba(0,0,0,.3);
  padding: 20px;
  display: flex;
  flex-direction: column;

  .form-field {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }

    input {
      margin-bottom: 0;
    }
  }

  .btn {
    margin-bottom: 0;
  }
}

.form-alternative-actions {
  font-size: 14px;
  text-align: center;

  a {
    color: $gray_medium;
  }
}