.admin-panel-dashboard {

  .col-12 {
    margin-bottom: 30px;
  }
}

.admin-card {
  background: $white;
  border-radius: 20px;
  padding: 40px 30px;
  border: 2px solid $white;
  transition: .25s linear;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 4px 25px 0 rgba(0,0,0,.2);
  text-decoration: none !important;
  height: 100%;
  margin-bottom: 30px;
  text-align: center;

  &:hover {
    border: 2px solid $dark_blue;
    box-shadow: 2px 8px 30px 0 rgba(0,0,0,.1);
  }

  h3 {
    text-decoration: none;
  }

  .icon {
    max-width: 160px;
    margin: 0 auto;
    margin-bottom: 20px;
  }
}

.rdw-editor-wrapper {
  border: 1px solid #ccc;
}

.rdw-editor-main {
  border-top: 1px solid #ddd;
}

.rdw-link-modal {
  height: auto !important;

  .rdw-link-modal-btn {
    width: auto !important;
    height: auto !important;
    background: gray;
  }
}
