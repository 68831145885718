.custom-message-textarea {
  min-height: 200px !important;
}

.recipients-list {
  margin-bottom: 20px;

  span.badge {
    margin-right: 5px;
    margin-bottom: 5px;
    display: inline-block;
    background: $dark_blue_10 !important;
    color: $dark_blue !important;
  }
}