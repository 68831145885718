@keyframes load {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader {
  border: 4px solid rgba(255, 255, 255, 0.4);
  border-left: 4px solid rgba(0, 0, 0, 0.15);
  animation: load 1s infinite linear;
  border-radius: 50%;
  width: 25px;
  height: 25px;
}

.btn-small {

  .loader{
    width: 20px;
    height: 20px;
  }
}

.last-saved {

  .loader {
    width: 16px;
    height: 16px;
    margin-left: 5px;
  }
}

.btn-primary-outline {
  .loader {
    border: 4px solid rgba(0, 0, 0, 0.05);
    border-left: 4px solid rgba(0, 0, 0, 0.2);
  }
}

button.btn-loading[disabled='disabled'],
button.btn-loading.btn-primary:disabled {
  background: $primary !important;
}
