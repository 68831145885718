button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.btn,
button,
input[type="submit"],
input[type="reset"],
input[type="button"] {
  outline: 0;
  color: $white;
  background: $primary;
  border: 1px solid $primary;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  margin-bottom: 20px;
  padding: 8px 18px;
  font-size: 16px;
  font-family: soleil, sans-serif;
  font-weight: 700;
  width: auto;
  border-radius: 12px;
  transition: all .15s linear;
  text-decoration: none !important;
}

.btn:visited,
button:visited,
input[type="submit"]:visited,
input[type="reset"]:visited,
input[type="button"]:visited {
  color: $white;
  background: $primary;
  border: 1px solid $primary;
}

.btn:hover,
button:hover,
input[type="submit"]:hover,
input[type="reset"]:hover,
input[type="button"]:hover {
  color: $white;
  background: $primary_hov;
  border: 1px solid $primary_hov;
}

.btn:active,
button:active,
input[type="submit"]:active,
input[type="reset"]:active,
input[type="button"]:active {
  color: $white;
  background: $primary_hov;
  border: 1px solid $primary_hov;
}

.btn:focus,
button:focus,
input[type="submit"]:focus,
input[type="reset"]:focus,
input[type="button"]:focus {
  color: $white;
  box-shadow: 0px 0px 0px 2px $white, 0px 0px 0px 4px $primary_hov;
  outline: 0;
}

.btn-outline {
  outline: 0;
  color: $primary;
  background: transparent;
  border: 1px solid $primary;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  margin-bottom: 20px;
  padding: 8px 18px;
  font-size: 16px;
  font-family: soleil, sans-serif;
  width: auto;
  transition: all .15s linear;
  text-decoration: none !important;
}

.btn-outline:visited {
  color: $primary;
  background: transparent;
  border: 1px solid $primary;
}

.btn-outline:hover {
  color: $primary_hov;
  background: transparent;
  border: 1px solid $primary_hov;
}

.btn-outline:active {
  color: $primary_hov;
  background: transparent;
  border: 1px solid $primary_hov;
}

.btn-outline:focus {
  color: $primary_hov;
  box-shadow: 0px 0px 0px 2px $white, 0px 0px 0px 4px $primary_hov;
  outline: 0;
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
}

.btn-close {
  &:hover {
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
    border: 0;
    opacity: 1;
  }
}