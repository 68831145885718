// Overriding styles for the Bootstrap modals

.modal-dialog {
  max-width: 800px !important;

  .modal-content {
    border: 0px !important;
    border-radius: 12px !important;

    .modal-title {
      font-weight: 700 !important;
      color: $dark_blue;
    }
  }

  form {
    margin-bottom: 0;
  }

  .btn-group {

    .btn {
      margin-bottom: 0;
    }
  }
}