a,
a:visited,
.link {
  color: $primary;
  transition: color .15s linear;
  text-decoration: none;
  outline: 0;
  cursor: pointer;
}

a:hover,
a:active,
a:focus,
.link:hover {
  color: $primary_hov;
  text-decoration: underline;
}

a.link-unstyled,
.link-unstyled {
  text-decoration: none !important;
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  cursor: inherit;
}