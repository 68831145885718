form {
  // margin-bottom: 20px;
}

fieldset {
  margin-bottom: 20px;
}

input,
input[type="text"],
input[type="password"],
input[type="email"],
textarea,
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid $gray_light;
  border-radius: 6px;
  padding: 6px 16px;
  height: 40px;
  font-size: 15px;
  font-style: normal !important;
  font-family: soleil, sans-serif;
  font-weight: 400;
  outline: none;
  color: $gray_dark;
  margin: 0;
  margin-bottom: 20px;
  width: 100%;
  max-width: 320px;
  display: inline-block;
  background: $white;
  box-sizing: border-box;
  transition: all .15s linear;
}

input:hover,
input[type="text"]:hover,
input[type="password"]:hover,
input[type="email"]:hover,
textarea:hover,
select {
  border: 1px solid $gray_light;
}

input:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
textarea:focus,
select:focus {
  border: 1px solid $primary;
  outline: none !important;
  outline: 0 !important;
  box-shadow: 0 0 0px 1px $primary !important;
}

.form-control {
  border-radius: 6px;

  &:focus {
    color: $gray_dark;
    background-color: #fff;
    border-color: $primary;
    outline: 0;
  }
}

.btn-group>.btn-group:not(:last-child)>.btn, .btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
  box-shadow: inset -1px 0 0 0 rgba(0,0,0,.2) !important;
}

textarea {
  height: auto;
  min-height: 120px;
  resize: vertical;
}

label,
legend {
  display: block;
  font-weight: 400;
  font-size: 16px;
  font-family: soleil, sans-serif;
}

.form-check {
  display: flex;
  align-items: center;
}

input[type="checkbox"] {
  display: inline-block;
  height: 34px;
  width: 18px !important;
  max-width: 18px !important;
  margin-bottom: 0;
  border: 1px solid $gray_light;
  margin: 2px 10px 0 0;
  box-shadow: inset 0 0 0px 2px $white !important;
  float: left;

  &:checked {
    border: 1px solid $gray_light;
  }

  &:hover,
  &:focus {
    border:1px solid $primary;
  }

  &:checked,
  &:checked:focus {
    background-color: $primary;
  }

}

input[type="radio"] {
  display: inline-block;
  height: 18px;
  width: 18px;
  margin-bottom: 0;
  border: 1px solid $gray_light;
  margin: 2px 10px 0 0;
  border-radius: 50%;
  box-shadow: inset 0 0 0px 2px $white !important;
  float: left;

  &:hover {
    border: 1px solid $primary;
  }

  &:checked,
  &:checked:focus {
    background: $primary;
  }
}

input[disabled='disabled'],
textarea[disabled='disabled'] {
  background: $gray_light !important;
}

div[class$="c-container"] {
  margin-bottom: 20px;

  input {
    margin-bottom: 0;
    height: 27px;
    box-shadow: none !important;

    &:focus {
      box-shadow: none !important;
    }
  }
}

// .toggle-wrapper {
//   float:left;
//   width:100%;
//   font-family: soleil, sans-serif;

//   .toggle-title {
//     cursor:pointer;
//     position:relative;
//     display:inline-block;
//     padding-right:22px;
//     margin-bottom:0 !important;
//     font-weight:600;
//     transition: color .15s linear;

//     &:hover {
//       color:$red;

//       &:after {
//         background: url(../img/directional-sprite.svg) -120px 0px;
//         top:6px;
//       }
//     }
//   }

//   .toggle-title:after {
//     content:'';
//     display:block;
//     position:absolute;
//     right:0;
//     top:4px;
//     height:16px;
//     width:16px;
//     background: url(../img/directional-sprite.svg) -120px -16px;
//     transform: rotate(90deg);
//     transition: transform .15s linear, top .15s linear;
//   }

//   .toggle-content {
//     float:left;
//     width:100%;
//     max-height:0;
//     overflow:hidden;
//     opacity:0;
//     transition: all .15s linear;
//   }

//   &.expand {

//     .toggle-title {
//       margin-bottom:25px !important;

//       &:hover {

//         &:after {
//           background: url(../img/directional-sprite.svg) -120px 0px;
//           top:2px;
//         }
//       }
//     }

//     .toggle-title:after {
//       background: url(../img/directional-sprite.svg) -120px -16px;
//       transform: rotate(-90deg);
//     }

//     .toggle-content{
//       max-height:1000px;
//       opacity:1;
//     }
//   }

//   &:after {
//     content:'';
//     display:block;
//     clear:both;
//   }
// }