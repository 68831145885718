html,
body {
  color: $gray_dark;
  margin: 0;
  padding: 0;
  font-family: brother-1816-printed, sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
  width:100%;
}

*:focus {
  outline: none;
}

*,
*:after,
*:before {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

::-moz-selection {
  background: rgba(0, 99, 221, 0.3);
  text-shadow: none; }

::selection {
  background: rgba(0, 99, 221, 0.3);
  text-shadow: none; }

body {
  padding: 90px 0 0 0;
  overflow: auto;
  overscroll-behavior: none; }

main {
  padding: 0 0 50px 0;
}

.container-fluid {
  width: 100%;
  max-width: 1200px;
}

.page-heading-section {
  padding: 30px 0 15px 0;
  background: $primary_10o;
  margin-bottom: 50px;
}

.page-heading {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
  }

  .page-title {
    line-height: 1em;
  }

  .btn {
    margin: 0;
  }
}

.m-0 { margin: 0;}

.mb-5 { margin-bottom: 5px !important; }
.mb-10 { margin-bottom: 10px !important; }
.mb-20 { margin-bottom: 20px !important; }
.mb-30 { margin-bottom: 30px !important; }
.mb-40 { margin-bottom: 40px !important; }
.mb-50 { margin-bottom: 50px !important; }
.mb-60 { margin-bottom: 60px !important; }
.mb-70 { margin-bottom: 70px !important; }
.mb-80 { margin-bottom: 80px !important; }
.mb-90 { margin-bottom: 90px !important; }
.mb-100 { margin-bottom: 100px !important; }
.mb-110 { margin-bottom: 110px !important; }
.mb-120 { margin-bottom: 120px !important; }
.mb-130 { margin-bottom: 130px !important; }
.mb-140 { margin-bottom: 140px !important; }
.mb-150 { margin-bottom: 150px !important; }

.mt-10 { margin-top: 10px !important; }
.mt-20 { margin-top: 20px !important; }
.mt-30 { margin-top: 30px !important; }
.mt-40 { margin-top: 40px !important; }
.mt-50 { margin-top: 50px !important; }
.mt-60 { margin-top: 60px !important; }
.mt-70 { margin-top: 70px !important; }
.mt-80 { margin-top: 80px !important; }
.mt-90 { margin-top: 90px !important; }
.mt-100 { margin-top: 100px !important; }
.mt-110 { margin-top: 110px !important; }
.mt-120 { margin-top: 120px !important; }
.mt-130 { margin-top: 130px !important; }
.mt-140 { margin-top: 140px !important; }
.mt-150 { margin-top: 150px !important; }
.mt-250 { margin-top: 250px !important; }

.mr-5 { margin-right: 5px !important; }
.mr-10 { margin-right: 10px !important; }
.mr-20 { margin-right: 20px !important; }

.p-0 { padding: 0; }
.p-10 { padding: 10px; }
.p-20 { padding: 20px; }
.p-30 { padding: 30px; }
.p-40 { padding: 40px; }

.pb-10 { padding-bottom: 10px !important; }
.pb-20 { padding-bottom: 20px !important; }
.pb-30 { padding-bottom: 30px !important; }
.pb-40 { padding-bottom: 40px !important; }
.pb-50 { padding-bottom: 50px !important; }
.pb-60 { padding-bottom: 60px !important; }
.pb-70 { padding-bottom: 70px !important; }
.pb-80 { padding-bottom: 80px !important; }
.pb-90 { padding-bottom: 90px !important; }
.pb-100 { padding-bottom: 100px !important; }
.pb-180 { padding-bottom: 180px !important; }

.pt-10 { padding-top: 10px !important; }
.pt-20 { padding-top: 20px !important; }
.pt-30 { padding-top: 30px !important; }
.pt-40 { padding-top: 40px !important; }
.pt-50 { padding-top: 50px !important; }
.pt-60 { padding-top: 60px !important; }
.pt-70 { padding-top: 70px !important; }
.pt-80 { padding-top: 80px !important; }
.pt-90 { padding-top: 90px !important; }
.pt-100 { padding-top: 100px !important; }
.pt-180 { padding-top: 100px !important; }

.max-20 {max-width: 20px;}
.max-25 {max-width: 25px;}
.max-40 {max-width: 40px;}
.max-50 {max-width: 50px;}
.max-60 {max-width: 60px;}
.max-75 {max-width: 75px;}
.max-80 {max-width: 80px;}
.max-85 {max-width: 85px;}
.max-90 {max-width: 90px;}
.max-95 {max-width: 95px;}
.max-100 {max-width: 100px;}
.max-110 {max-width: 110px;}
.max-120 {max-width: 120px;}
.max-130 {max-width: 130px;}
.max-140 {max-width: 140px;}
.max-150 {max-width: 150px;}
.max-160 {max-width: 160px;}
.max-170 {max-width: 170px;}
.max-180 {max-width: 180px;}
.max-190 {max-width: 190px;}
.max-200 {max-width: 200px;}
.max-220 {max-width: 220px;}
.max-240 {max-width: 240px;}
.max-260 {max-width: 260px;}
.max-280 {max-width: 280px;}
.max-300 {max-width: 300px;}
.max-320 {max-width: 320px;}
.max-340 {max-width: 340px;}
.max-360 {max-width: 360px;}
.max-380 {max-width: 380px;}
.max-400 {max-width: 400px;}
.max-420 {max-width: 420px;}
.max-440 {max-width: 440px;}
.max-460 {max-width: 460px;}
.max-480 {max-width: 480px;}
.max-500 {max-width: 500px;}
.max-520 {max-width: 520px;}
.max-540 {max-width: 540px;}
.max-560 {max-width: 560px;}
.max-580 {max-width: 580px;}
.max-600 {max-width: 600px;}
.max-620 {max-width: 620px;}
.max-640 {max-width: 640px;}
.max-660 {max-width: 660px;}
.max-680 {max-width: 680px;}
.max-700 {max-width: 700px;}
.max-720 {max-width: 720px;}
.max-740 {max-width: 740px;}
.max-760 {max-width: 760px;}
.max-780 {max-width: 780px;}
.max-800 {max-width: 800px;}
.max-820 {max-width: 820px;}
.max-840 {max-width: 840px;}
.max-860 {max-width: 860px;}
.max-880 {max-width: 880px;}
.max-900 {max-width: 900px;}
.max-920 {max-width: 920px;}
.max-940 {max-width: 940px;}
.max-960 {max-width: 960px;}
.max-980 {max-width: 980px;}
.max-1000 {max-width: 1000px;}
.max-1020 {max-width: 1020px;}
.max-1040 {max-width: 1040px;}
.max-1060 {max-width: 1060px;}
.max-1080 {max-width: 1080px;}
.max-1100 {max-width: 1100px;}
.max-1120 {max-width: 1120px;}
.max-1140 {max-width: 1140px;}
.max-1160 {max-width: 1160px;}
.max-1180 {max-width: 1180px;}
.max-1200 {max-width: 1200px;}
.max-1220 {max-width: 1220px;}
.max-1240 {max-width: 1240px;}
.max-1260 {max-width: 1260px;}
.max-1280 {max-width: 1280px;}
.max-1300 {max-width: 1300px;}

.center-text {
  text-align: center;
}

.hide {
  display: none !important;
}

.show {
  display: block;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

/*
==============================================================
 Responsive Shapes
============================================================== */

.rectangle169 {
  width:100%;
  position:relative;
  display:block;
  float:left;
  overflow: hidden;

  .rectangle169-content {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  &:before{
    content: "";
    display: block;
    padding-top: 56.25%;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}